import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Box } from '../Box';
import Logo from './Logo';
import LogoResponsive from './LogoResponsive';
import { Props, Playground } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "logos"
    }}>{`Logos`}</h1>
    <h2 {...{
      "id": "logo"
    }}>{`Logo`}</h2>
    <p>{`The Logo is the basic building block of most elements in the Hoppin Design System.`}</p>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={Logo} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <Playground __position={1} __code={'<Logo margin=\"xlarge\" />\n<Logo context=\"shadower\" margin=\"xlarge\" />\n<Logo context=\"host\" margin=\"xlarge\" />\n<Box bg=\"primary.base\" padding=\"large\">\n  <Logo context=\"whiteout\" margin=\"xlarge\" />\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Box,
      Logo,
      LogoResponsive,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Logo margin='xlarge' mdxType="Logo" />
  <Logo context='shadower' margin='xlarge' mdxType="Logo" />
  <Logo context='host' margin='xlarge' mdxType="Logo" />
  <Box bg='primary.base' padding='large' mdxType="Box">
    <Logo context='whiteout' margin='xlarge' mdxType="Logo" />
  </Box>
    </Playground>
    <p>{`Add prop `}<inlineCode parentName="p">{`size='icon'`}</inlineCode>{` to render the hop icon only.`}</p>
    <Playground __position={2} __code={'<Logo size=\"icon\" margin=\"xlarge\" />\n<Logo size=\"icon\" context=\"shadower\" margin=\"xlarge\" />\n<Logo size=\"icon\" context=\"host\" margin=\"xlarge\" />'} __scope={{
      props,
      DefaultLayout,
      Box,
      Logo,
      LogoResponsive,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Logo size="icon" margin="xlarge" mdxType="Logo" />
  <Logo size="icon" context="shadower" margin="xlarge" mdxType="Logo" />
  <Logo size="icon" context="host" margin="xlarge" mdxType="Logo" />
    </Playground>
    <h2 {...{
      "id": "logoresponsive"
    }}>{`LogoResponsive`}</h2>
    <p>{`The LogoResponsive will render the Logo in icon size for mobile devices and full logo size on larger screens.`}</p>
    <h3 {...{
      "id": "props-1"
    }}>{`Props`}</h3>
    <Props of={LogoResponsive} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <Playground __position={4} __code={'<LogoResponsive margin=\"xlarge\" />\n<LogoResponsive context=\"shadower\" margin=\"xlarge\" />\n<LogoResponsive context=\"host\" margin=\"xlarge\" />'} __scope={{
      props,
      DefaultLayout,
      Box,
      Logo,
      LogoResponsive,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <LogoResponsive margin="xlarge" mdxType="LogoResponsive" />
  <LogoResponsive context="shadower" margin="xlarge" mdxType="LogoResponsive" />
  <LogoResponsive context="host" margin="xlarge" mdxType="LogoResponsive" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      